import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import { ACL } from '@vl/mod-utils/ACL';

import { useLocalStorage } from '@vl/hooks/useLocalStorageWeb';
import { useAsyncCallState } from '@vl/hooks/useAsyncCallState';
import { createPromise } from '@vl/mod-utils/createPromise';

import firebase from 'gatsby-plugin-firebase';
import qs from 'querystring';
import fibGatsbyFns from '@vl/mod-clients/fibGatsbyFns';
import modConfig from '@vl/mod-config/web';
import zoomSdk from '@zoom/appssdk';

const isBrowser = () => typeof window !== 'undefined';

const getFirebase = _.memoize(() => {
  return firebase;
});

const checkEmailVerification = async (user) => {
  const emailVerified = _.get(user, 'emailVerified');
  return emailVerified;
};

const GATSBY_AUTH_ORIGIN = process.env.GATSBY_AUTH_ORIGIN;

const AUTH_CLAIM_KEY = 'https://hasura.io/jwt/claims';
const AUTH_ROLE_KEY = 'x-hasura-allowed-roles';

const createQueryParams = (params) => qs.stringify(params);

const INIT_SYM = {};

const loaderRef = createPromise({
  timeoutMs: 10 * 1000,
});

modConfig.set({
  HASURA_GRAPHQL_PRE_LOADER: async () => {
    await loaderRef.promise;
  },
});

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            firebase: hook((ctx) => {
              const firebase = getFirebase();
              return firebase;
            }),
            authModel: hook((ctx) => {
              if (!isBrowser()) return {};
              const ref = React.useRef({});
              const route = useRoute();

              const [zoomConfig, $zoomConfig] = React.useState(null);

              const [currentUser, $currentUser] = useLocalStorage('@NA::currentUser', null);
              const userId = _.get(currentUser, 'uid');

              const [authTokens, $authTokens] = useLocalStorage('@NA::authTokens', INIT_SYM);
              const [apiToken, $apiToken] = useLocalStorage('@NA::apiToken', '');
              const [claims, $claims] = React.useState({});

              const [isClaimToken, $isClaimToken] = React.useState(true);
              const [isLoadingRedirectResult, $isLoadingRedirectResult] = React.useState(true);

              Object.assign(ref.current, {
                currentUser,
                $currentUser,
                userId,
                authTokens,
                $authTokens,
                claims,
                $claims,
                isLoadingRedirectResult,
                $isLoadingRedirectResult,
                isClaimToken,
                $isClaimToken,
                apiToken,
                $apiToken,
                zoomConfig,
                $zoomConfig,
              });

              if (!ref.current.loginPromise) {
                ref.current.loginPromise = createPromise();
              }

              React.useEffect(() => {
                if (!apiToken) {
                  loaderRef.resolve(apiToken);
                } else {
                  loaderRef.resolve(apiToken);
                }
                modConfig.set({ HASURA_GRAPHQL_JWT_TOKEN: apiToken, WALLET_GRAPHQL_JWT_TOKEN: apiToken });
              }, [apiToken]);

              const tokenUtil = React.useMemo(
                () => ({
                  refreshToken: async (user) => {
                    const uid = _.get(user, 'uid');
                    console.log(`refresh Token for ${uid}`);
                    try {
                      // turn onloading
                      ctx.apply('loadingModel.showLoading');

                      await ctx
                        .get('firebase')
                        .functions()
                        .httpsCallable('triggers-user-refreshTokenCall')({ uid });
                      // force to reload token
                      await user.getIdToken(true);

                      await new Promise((res) => setTimeout(res, 1000));

                      const newToken = await tokenUtil.updateTokens(user);
                      return newToken;
                    } catch (err) {
                      // token refresh error. Should restart the app?
                      console.error(err);
                    } finally {
                      // turn off loading;
                      ctx.apply('loadingModel.hideLoading');
                    }
                  },
                  updateTokens: async (user, accountId) => {
                    // const token = await user.getIdToken();
                    const token = await user.getIdToken();
                    const idTokenResult = await user.getIdTokenResult();
                    const hasuraClaim = idTokenResult.claims[AUTH_CLAIM_KEY];
                    try {
                      modConfig.register(
                        'refreshToken',
                        () => ref.current.currentUser && tokenUtil.refreshToken(ref.current.currentUser)
                      );
                    } catch (err) {
                      console.log('err', err);
                    }

                    if (hasuraClaim) {
                      const uid = _.get(user, 'uid');
                      ref.current.$authTokens && ref.current.$authTokens({ token });
                      ref.current.$isClaimToken && ref.current.$isClaimToken(false);
                      if (!ref.current.apiToken) {
                        const accessToken = _.get(
                          await ctx
                            .get('firebase')
                            .functions()
                            .httpsCallable('triggers-tool-apiTokenCall')({ uid, accountId }),
                          'data.signinToken'
                        );
                        ref.current.$apiToken && ref.current.$apiToken(accessToken);
                      }
                      loaderRef.resolve(true);
                      return token;
                    }
                  },
                  reloadApiToken: async (user) => {
                    const uid = _.get(user, 'uid');
                    const accessToken = _.get(
                      await ctx
                        .get('firebase')
                        .functions()
                        .httpsCallable('triggers-tool-apiTokenCall')({ uid }),
                      'data.signinToken'
                    );
                    ref.current.$apiToken && ref.current.$apiToken(accessToken);
                  },
                  refreshTokenTimer: null,
                }),
                []
              );

              const auth = React.useMemo(() => {
                // Listen for authentication state to change.
                getFirebase()
                  .auth()
                  .onAuthStateChanged(async (user) => {
                    if (user) {
                      try {
                        // send email verification
                        await checkEmailVerification(user);

                        // custom hasura claims
                        // const token = await updateTokens(user);
                        const token = await tokenUtil.updateTokens(user);
                        if (!token) {
                          // try to refresh token with claims
                          // call refreshToken service
                          tokenUtil.refreshToken(user);
                        }
                        const idTokenResult = await user.getIdTokenResult();
                        ref.current.$claims(_.get(idTokenResult, 'claims'));
                        ref.current.$currentUser(user);
                        ref.current.loginPromise && ref.current.loginPromise.resolve(user);
                      } catch (err) {
                        console.log('error', err);
                      }
                    } else {
                      ref.current.$currentUser(user);
                      ref.current.$authTokens(null);
                      ref.current.$isClaimToken(false);
                    }
                  });
                const routeParams = route.getParams();
                const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                if (redirectUrl) {
                  getFirebase()
                    .auth()
                    .getRedirectResult()
                    .then(async (result) => {
                      const user = _.get(result, 'user');
                      if (user) {
                        await authModel.onLoginRedirect();
                      }
                      $isLoadingRedirectResult(false);
                    });
                } else {
                  $isLoadingRedirectResult(false);
                }

                return getFirebase().auth;
                // eslint-disable-next-line
              }, [ref]);

              const envRef = React.useRef(null);
              const getEnv = async () => {
                if (!envRef.current) {
                  const promise = new Promise((res, rej) => {
                    envRef.current = { res, rej };
                  });
                  Object.assign(envRef.current, { promise });
                  const env = await getFirebase()
                    .database()
                    .ref(`/config/${process.env.REACT_NATIVE_APP_NAME}/env`)
                    .once('value');
                  envRef.current.res(env.val());
                }
                return envRef.current.promise;
              };

              const authModel = {
                firebase: getFirebase(),
                auth,
                getEnv,
                dbh: getFirebase().firestore(),
                db: getFirebase().database(),
                functions: getFirebase().functions(),
                // remoteConfig,
                isClaimToken,
                currentUser: ref.current.currentUser,
                isLoadingRedirectResult,
                changeAccount: (accountId) => {
                  if (ref.current.currentUser) {
                    tokenUtil.updateTokens(ref.current.currentUser, accountId);
                  }
                },
                reloadApiToken: () => {
                  if (ref.current.currentUser) {
                    tokenUtil.reloadApiToken(ref.current.currentUser);
                  }
                },
                hasRole: (...roles) => {
                  const claimRoles = _.get(ref.current.claims, [AUTH_CLAIM_KEY, AUTH_ROLE_KEY], []);
                  for (let role of roles) {
                    if (_.includes(claimRoles, role)) {
                      return true;
                    }
                  }
                },
                isMod: () => {
                  return authModel.hasRole('mod');
                },
                setTargetProfileId: (id) => {
                  ref.current.profileId = id;
                },
                getTargetProfileId: () => {
                  return ref.current.profileId || authModel.getUserId();
                },

                getUserId: () => {
                  return _.get(ref.current.currentUser, 'uid');
                },

                getIdToken: async () => {
                  return getFirebase()
                    .auth()
                    .currentUser.getIdToken(true);
                },

                getSigninToken: async () => {
                  const res = await fibGatsbyFns.getClient().post('triggers-user-signinToken', {
                    uid: authModel.getUserId(),
                  });

                  return _.get(res, 'signinToken');
                },

                isAuthenticated: () => {
                  return !!ref.current.currentUser;
                },

                isVerifiedUser: () => {
                  return checkEmailVerification(ref.current.currentUser) === true;
                },

                updatePassword: (currentPassword, newPassword) => {
                  ref.current.currentUser
                    .reauthenticateWithCredential(
                      getFirebase().auth.EmailAuthProvider.credential(ref.current.currentUser.email, currentPassword)
                    )
                    .then(() => {
                      ref.current.currentUser
                        .updatePassword(newPassword)
                        .then(() => {
                          return {
                            code: 0,
                            detail: ctx.apply('i18n.t', 'Profile.updatePass', 'Update password'),
                            message: ctx.apply('i18n.t', 'Profile.updatePassSuccess', 'Update password successfully!'),
                          };
                        })
                        .catch((error) => {
                          throw Error(_.get(error, 'message', ''));
                        });
                    })
                    .catch((err) => {
                      throw Error(ctx.apply('i18n.t', 'Profile.currentPassword', _.get(err, 'message', '')));
                    });
                },

                forgotPassword: async (emailReset) => {
                  try {
                    return getFirebase()
                      .auth()
                      .sendPasswordResetEmail(emailReset)
                      .then(() => {
                        return {
                          code: 0,
                          detail: ctx.apply('i18n.t', 'Profile.sendEmail', 'Send mail'),
                          message: ctx.apply('i18n.t', 'Profile.sendEmailSuccess', 'Update password successfully!'),
                        };
                      });
                  } catch (err) {
                    console.log(err);
                  }
                },

                verifyResetCode: (actionCode) => {
                  return getFirebase()
                    .auth()
                    .verifyPasswordResetCode(actionCode);
                },

                resetPassword: async (actionCode, password) => {
                  const auth = getFirebase().auth();
                  await auth.confirmPasswordReset(actionCode, password);
                },

                authorizeUrl(authorizeOptions) {
                  return `${GATSBY_AUTH_ORIGIN}/authorize?${createQueryParams(authorizeOptions)}`;
                },

                generateLoginRedirectUrl: (params, options) => {
                  const redirectUrl = route.redirectUrl(params, options);
                  const redirectParams = { redirect_url: redirectUrl };
                  return `/login?${createQueryParams(redirectParams)}`;
                },

                generateRegisterRedirectUrl: (params, options) => {
                  const redirectUrl = route.redirectUrl(params, options);
                  const redirectParams = { redirect_url: redirectUrl };
                  return `/signup?${createQueryParams(redirectParams)}`;
                },

                onLoginRedirect: async (reload = true) => {
                  // const routeParams = route.getParams();
                  // const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                  const redirectUrl = _.get(window, 'location.origin');
                  ref.current.loginPromise && (await ref.current.loginPromise.promise);
                  if (redirectUrl) {
                    try {
                      const urlObj = new URL(redirectUrl);
                      route.navigateExternal(urlObj.toString());
                    } catch (err) {
                      console.log('redirect error', err);
                    }
                  } else {
                    reload && route.reload();
                  }
                },

                onLogoutRedirect: () => {
                  // const routeParams = route.getParams();
                  // const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                  const redirectUrl = _.get(window, 'location.origin');
                  if (redirectUrl) {
                    try {
                      const urlObj = new URL(redirectUrl);
                      route.navigateExternal(urlObj.toString());
                    } catch (err) {
                      console.log('redirect error', err);
                    }
                  }
                },

                ...useAsyncCallState({
                  login: async () => {
                    const loginUrl = authModel.generateLoginRedirectUrl();
                    route.navigateExternal(loginUrl);
                  },
                  register: async () => {
                    const registerUrl = authModel.generateRegisterRedirectUrl();
                    route.navigateExternal(registerUrl);
                  },
                  logout: async (redirect) => {
                    ref.current.currentUser &&
                      (await getFirebase()
                        .auth()
                        .signOut());
                    ref.current.$currentUser && ref.current.$currentUser(null);
                    ref.current.$cUser && ref.current.$cUser(null);
                    ref.current.$apiToken && ref.current.$apiToken('');
                    ref.current.loginPromise = null;

                    if (_.isFunction(redirect)) {
                      await redirect();
                    }
                    authModel.onLogoutRedirect();
                  },
                  emailLogin: async (username, password) => {
                    try {
                      // addScope
                      await authModel.auth().signInWithEmailAndPassword(username, password);
                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                      throw err;
                    }
                  },
                  facebookLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.FacebookAuthProvider();
                      // addScope
                      await getFirebase()
                        .auth()
                        .signInWithPopup(provider);

                      // redirect on login success
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }
                      authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },
                  googleLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.GoogleAuthProvider();
                      // addScope
                      const result = await getFirebase()
                        .auth()
                        .signInWithRedirect(provider);
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }

                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },

                  appleLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.OAuthProvider('apple.com');
                      // addScope
                      provider.addScope('email');
                      provider.addScope('name');
                      await getFirebase()
                        .auth()
                        .signInWithPopup(provider);
                      // // redirect on login success
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }
                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },
                  checkRedirectResult: async () => {
                    const routeParams = route.getParams();
                    const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                    if (redirectUrl) {
                      getFirebase()
                        .auth()
                        .getRedirectResult()
                        .then((result) => {
                          const user = _.get(result, 'user');
                          if (user) {
                            authModel.onLoginRedirect();
                          }
                        });
                    }
                  },
                  init: async () => {},
                }),
                zoomConfig: ref.current.zoomConfig,
              };

              const configZoomApp = async () => {
                if (!window) return;
                // const { zoomSdk } = window;
                // console.log('zoomSdkzoomSdkzoomSdk', zoomSdk);
                try {
                  // const configResponse = await zoomSdk.config({
                  //   version: '0.16',
                  //   popoutSize: { width: 480, height: 360 },
                  //   capabilities: ['shareApp'],
                  // });
                  const configResponse = null;
                  // const runningContext = await zoomSdk.getRunningContext();
                  const runningContext = null;
                  // const userContext = await zoomSdk.getUserContext();
                  // const cameras = await zoomSdk.listCameras();
                  ref.current.$zoomConfig({
                    config: {
                      configResponse,
                      runningContext,
                      // cameras,
                      // userContext,
                    },
                    zoomSdk,
                  });
                } catch (err) {
                  console.log('err', err);
                  ref.current.$zoomConfig({
                    config: { err: `${err}` },
                    zoomSdk,
                    error: err,
                  });
                }
              };
              React.useEffect(() => {
                authModel.init();
                configZoomApp();
                ACL.setRef('authModel', authModel);
                // eslint-disable-next-line
              }, []);
              ctx.apply('REF.setRef', 'authModel', authModel);
              ctx.apply('REF.setRef', 'firebase', getFirebase());
              return authModel;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
