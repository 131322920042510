import React from 'react';

// import { ctx } from '@vl/redata';
// import DIV from '@vl/redata/DIV.macro';

// import SEO from '@uz/unitz-layout-web/SEO';

// import Layout from '@uz/unitz-layout-web/LayoutZoomToolMenu';
// import CtfPage404 from '@uz/unitz-pages/404Page';

export const PageNotFound = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      Not Found
    </div>
  );
};

export default PageNotFound;
