import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRouteDe';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import { ACL } from '@vl/mod-utils/ACL';

import { decode } from '@vl/mod-utils/jwt';

import hasuraClient from '@vl/mod-clients/hasuraApp';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            accountModel: hook((ctx) => {
              const route = useRoute();
              const pageContext = useRoute().getPageContext();
              const [account_id, $account_id] = useLocalStorage('@UZ::accountID');
              const [isLoading, $isLoading] = React.useState(true);
              const [apiToken] = useLocalStorage('@NA::apiToken');
              const accountModel = {
                getAccountId: (ignoreToken = false) => {
                  let accountId = _.get(pageContext, 'accountId');
                  if (accountId) return accountId;
                  accountId = _.get(pageContext, 'id');
                  if (accountId) return accountId;
                  accountId = _.get(route, 'params.accountId');
                  if (accountId) return accountId;
                  try {
                    if (!ignoreToken && (_.isUndefined(account_id) || account_id === 'undefined')) {
                      const data = apiToken ? decode(apiToken) : '';
                      const hsr = _.get(data, 'https://hasura.io/jwt/claims');
                      const ac_id = _.get(hsr, 'x-hasura-account-id') || '';
                      $account_id(ac_id);
                    }
                    if (account_id === 'undefined') return '';
                    return account_id;
                  } catch (error) {

                  }
                },

                userRole: () => {
                  const [apiToken] = useLocalStorage('@NA::apiToken');
                  const data = apiToken ? decode(apiToken) : '';
                  const hsr = _.get(data, 'https://hasura.io/jwt/claims');
                  const hsr_roles = _.replace(_.get(hsr, 'x-hasura-account-roles'), /[{}]/g, '');
                  const roles = _.split(hsr_roles, ',');
                  return roles;
                },

                loadPermissions: async () => {
                  let permissions = [];
                  try {
                    const account_id = accountModel.getAccountId();
                    if (!account_id) return;
                    const user_id = ctx.apply('authModel.getUserId');
                    const resData = await hasuraClient.getClient().query(
                      `query MyQuery(
                        $where: b2b_user_role_permission_bool_exp
                      ) {
                        b2b_user_role_permission(
                          distinct_on: permission_id,
                          where: $where
                        ) {
                          account_id
                          user_id
                          permission_id
                          role_id
                        }
                        b2b_account(
                          where: {id: {_eq: "${account_id}"}}
                        ) {
                          account_profile {
                            id
                            display_name
                            avatar_url
                          }
                        }
                      }`,
                      {
                        where: {
                          account_id: { _eq: account_id },
                          ...(user_id ? { user_id: { _eq: user_id } } : {}),
                        }
                      }
                    );
                    permissions = _.get(resData, 'b2b_user_role_permission', []);
                    // TODO: detect account member changed then reload api token
                    if (!_.get(permissions, 'length')) {
                      await ctx.apply('authModel.reloadApiToken');
                    }
                    // update account params
                    const accountData = _.get(resData, 'b2b_account.0');
                    const account = {
                      ...accountData,
                    };
                    const pageContext = useRoute().getPageContext();
                    _.merge(pageContext, {
                      params: {
                        ..._.omit(account, ['id']),
                      }
                    });
                  } catch (err) {
                    console.log('err', err);
                  }
                  ACL.setPermissions(permissions);
                },
                isLoading,
                checkAccess: () => {
                  if (accountModel.isLoading) return false;
                  const checkAccess = useRoute().checkAccess();
                  return checkAccess;
                },
              };
              ctx.apply('REF.setRef', 'accountModel', accountModel);
              React.useEffect(() => {
                ACL.setLoading(true);
                accountModel.loadPermissions().then(() => {
                  ACL.setLoading(false);
                  $isLoading(false);
                });
              }, []);
              return accountModel;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
