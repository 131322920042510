import _ from 'lodash';
import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { bindings, hook } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRouteDe';
import i18n from '@uz/mod-translations/web';
import { LANG_MAP_FULL } from '@uz/mod-translations/constants';
import AsyncRender from '@uz/unitz-components-web/AsyncRender';
import '@uz/mod-translations/loader/gatsby';

export const I18nWebProvider = bindings({
  I18nWebProvider: {
    rules: [
      [
        'data',
        {
          data: {
            i18n: hook(() => {
              return {
                ...i18n,
                n: (...params) => {
                  if (params.length > 0 && typeof params[0] === 'string') {
                    return i18n.t(...params);
                  }

                  return _.get(params, '0.text');
                },
                suffixSelectionWeb: (value) => {
                  const route = useRoute();
                  const pageContext = route.getPageContext();
                  const lang = _.get(pageContext, 'lang', 'de');
                  const locale = _.get(LANG_MAP_FULL, lang, '');
                  return value && locale ? `:${value}_${locale}` : '';
                },
              };
            }),
          },
        },
      ],
    ],
  },
})(({ children }) => {
  return (
    <AsyncRender render={async () => {
      const route = useRoute();
      const lang = route.getCurrentLang();
      i18n.locale = lang;
      await i18n?.loadTranslation(lang);
      return (<DIV className="I18nWebProvider">{children}</DIV>);
    }} />
  );
});

export default I18nWebProvider;
