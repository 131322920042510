import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import CookieConsent from 'react-cookie-consent';

const View8 = ({ children }) => {
  return (
    <DIV className="component">
      {/* {ctx.get('authModel.zoomConfig') && (
        <div>Running info {JSON.stringify(ctx.get('authModel.zoomConfig.config'))}</div>
      )} */}
      {children}
      <CookieConsent
        location="bottom"
        buttonText={ctx.apply('i18n.t', 'GDPR.consent.acceptButtonText')}
        cookieName="gdpr_accept"
        declineButtonText={ctx.apply('i18n.t', 'GDPR.consent.declineButtonText')}
        enableDeclineButton
        // style={{ background: "#2B373B" }}
        // buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ctx.apply('i18n.t', 'GDPR.consent.noticeMessageTxt')}
      </CookieConsent>
    </DIV>
  );
};

export default displayName(View8);
